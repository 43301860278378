
import { Component, Mixins } from 'vue-property-decorator';
import { ListingComponentProps } from '@/types';

import RunCollectionMixin from '@/mixins/data-operations/collection/run-collection-mixin';
import ListingComponent from '@/components/data-operations/common/listing/ListingComponent.vue';
import { TABLE_TO_STORAGE_RUNS_ITEM } from '@/constants/router/routes-names';
import {
	DAG_EXECUTION_DATE,
	DESTINATION_BUCKET,
	DURATION,
	ENVIRONMENT,
	FIRESTORE_CONF_DOC_ID,
	OUTPUT_FILENAME,
	RUN_CLIENT_VERSION,
	STATUS,
} from '@/constants/data-operations/listing/header-items';
import { tableToStorageRuns } from '@/store/modules/easy-firestore/table-to-storage-runs';
import { tableToStorageRunsLight } from '@/store/modules/easy-firestore/table-to-storage-runs-light';

@Component({
	components: { ListingComponent },
})
export default class CollectionListing extends Mixins(RunCollectionMixin) {
	get listingComponentProps(): ListingComponentProps {
		return {
			type: this.listingType,
			moduleName: tableToStorageRuns.moduleName,
			moduleNameLight: tableToStorageRunsLight.moduleName,
			headers: [
				ENVIRONMENT,
				RUN_CLIENT_VERSION,
				FIRESTORE_CONF_DOC_ID,
				DESTINATION_BUCKET,
				OUTPUT_FILENAME,
				STATUS,
				DAG_EXECUTION_DATE,
				DURATION,
			],
			overriddenColumns: ['firestore_conf_doc_id', 'dag_execution_date', 'duration'],
			isOtherRunDisplay: this.isOtherRunDisplay,
			jobId: this.jobId,
		};
	}

	get routeName() {
		return TABLE_TO_STORAGE_RUNS_ITEM;
	}
}
