
import { Component, Mixins } from 'vue-property-decorator';
import { ListingComponentProps } from '@/types';

import RunCollectionMixin from '@/mixins/data-operations/collection/run-collection-mixin';
import ListingComponent from '@/components/data-operations/common/listing/ListingComponent.vue';
import { TABLES_TO_TABLES_RUNS_ITEM } from '@/constants/router/routes-names';
import {
	DAG_EXECUTION_DATE,
	DAG_ID,
	DURATION,
	ENVIRONMENT,
	RUN_CLIENT_VERSION,
	STATUS,
	WORKFLOW_LENGTH,
} from '@/constants/data-operations/listing/header-items';
import { getGbqToGbqRuns } from '@/store/modules/easy-firestore/get-gbq-to-gbq-runs';
import { getTableToTableRunsLight } from '@/store/modules/easy-firestore/get-table-to-table-runs-light';

@Component({
	components: { ListingComponent },
})
export default class CollectionListing extends Mixins(RunCollectionMixin) {
	get listingComponentProps(): ListingComponentProps {
		return {
			type: this.listingType,
			moduleName: getGbqToGbqRuns.moduleName,
			moduleNameLight: getTableToTableRunsLight.moduleName,
			headers: [ENVIRONMENT, RUN_CLIENT_VERSION, DAG_ID, WORKFLOW_LENGTH, STATUS, DAG_EXECUTION_DATE, DURATION],
			overriddenColumns: ['dag_id', 'dag_execution_date', 'duration'],
			isOtherRunDisplay: this.isOtherRunDisplay,
			jobId: this.jobId,
		};
	}

	get routeName() {
		return TABLES_TO_TABLES_RUNS_ITEM;
	}
}
