
import { Component, Mixins } from 'vue-property-decorator';
import { ListingComponentProps } from '@/types';

import RunCollectionMixin from '@/mixins/data-operations/collection/run-collection-mixin';
import ListingComponent from '@/components/data-operations/common/listing/ListingComponent.vue';

import { STORAGE_TO_STORAGE_RUNS_ITEM } from '@/constants/router/routes-names';
import {
	CONFIGURATION_ID,
	DAG_EXECUTION_DATE,
	DURATION,
	ENVIRONMENT,
	RUN_CLIENT_VERSION,
	STATUS,
	TRIGGERING_FILE,
} from '@/constants/data-operations/listing/header-items';
import { storageToStorageRuns } from '@/store/modules/easy-firestore/storage-to-storage-runs';
import { storageToStorageRunsLight } from '@/store/modules/easy-firestore/storage-to-storage-runs-light';

@Component({
	components: { ListingComponent },
})
export default class CollectionListing extends Mixins(RunCollectionMixin) {
	get listingComponentProps(): ListingComponentProps {
		return {
			type: this.listingType,
			moduleName: storageToStorageRuns.moduleName,
			moduleNameLight: storageToStorageRunsLight.moduleName,
			headers: [
				ENVIRONMENT,
				RUN_CLIENT_VERSION,
				CONFIGURATION_ID,
				TRIGGERING_FILE,
				STATUS,
				DAG_EXECUTION_DATE,
				DURATION,
			],
			overriddenColumns: ['triggering_file', 'dag_execution_date', 'duration'],
			isOtherRunDisplay: this.isOtherRunDisplay,
			jobId: this.jobId,
		};
	}

	get routeName() {
		return STORAGE_TO_STORAGE_RUNS_ITEM;
	}
}
