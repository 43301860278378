
import { Component, Mixins } from 'vue-property-decorator';
import { ListingComponentProps } from '@/types';

import RunCollectionMixin from '@/mixins/data-operations/collection/run-collection-mixin';
import ListingComponent from '@/components/data-operations/common/listing/ListingComponent.vue';
import { FILE_UTILITIES_RUNS_ITEM } from '@/constants/router/routes-names';
import {
	CONFIGURATION_ID,
	DAG_EXECUTION_DATE,
	DURATION,
	ENVIRONMENT,
	GCS_BUCKET,
	RUN_CLIENT_VERSION,
	STATUS,
	TRIGGERING_FILE,
} from '@/constants/data-operations/listing/header-items';
import { fileUtilitiesRuns } from '@/store/modules/easy-firestore/file-utilities-runs';
import { fileUtilitiesRunsLight } from '@/store/modules/easy-firestore/file-utilities-runs-light';

@Component({
	components: { ListingComponent },
})
export default class CollectionListing extends Mixins(RunCollectionMixin) {
	get listingComponentProps(): ListingComponentProps {
		return {
			type: this.listingType,
			moduleName: fileUtilitiesRuns.moduleName,
			moduleNameLight: fileUtilitiesRunsLight.moduleName,
			headers: [
				ENVIRONMENT,
				{
					...RUN_CLIENT_VERSION,
					value: 'configuration_context.configuration.client_version',
				},
				{
					...CONFIGURATION_ID,
					value: 'configuration_context.configuration.configuration_id',
					width: 300,
				},
				{
					...GCS_BUCKET,
					value: 'configuration_context.configuration.gcs_bucket',
					width: 200,
				},
				TRIGGERING_FILE,
				STATUS,
				DAG_EXECUTION_DATE,
				DURATION,
			],
			overriddenColumns: ['triggering_file', 'dag_execution_date', 'duration'],
			isOtherRunDisplay: this.isOtherRunDisplay,
			jobId: this.jobId,
		};
	}

	get routeName() {
		return FILE_UTILITIES_RUNS_ITEM;
	}
}
