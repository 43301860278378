
import { Component, Mixins } from 'vue-property-decorator';
import { ListingComponentProps } from '@/types';

import RunCollectionMixin from '@/mixins/data-operations/collection/run-collection-mixin';
import ListingComponent from '@/components/data-operations/common/listing/ListingComponent.vue';

import { apiToStorageRuns } from '@/store/modules/easy-firestore/api-to-storage-runs';
import { API_TO_STORAGE_RUNS_ITEM } from '@/constants/router/routes-names';
import {
	CONFIGURATION_ID,
	DAG_EXECUTION_DATE,
	ENVIRONMENT,
	RUN_CLIENT_VERSION,
	SOURCE_GCP_PROJECT_ID,
	STATUS,
	TOPIC,
} from '@/constants/data-operations/listing/header-items';

@Component({
	components: { ListingComponent },
})
export default class CollectionListing extends Mixins(RunCollectionMixin) {
	get listingComponentProps(): ListingComponentProps {
		return {
			type: this.listingType,
			moduleName: apiToStorageRuns.moduleName,
			headers: [
				ENVIRONMENT,
				RUN_CLIENT_VERSION,
				CONFIGURATION_ID,
				{ text: 'Source Type', sortable: true, value: 'configuration_context.source.type' },
				SOURCE_GCP_PROJECT_ID,
				TOPIC,
				{ text: 'Destinations', sortable: true, value: 'configuration_context.destinations' },
				STATUS,
				DAG_EXECUTION_DATE,
			],
			overriddenColumns: ['configuration_id', 'configuration_context.destinations', 'dag_execution_date'],
			isOtherRunDisplay: this.isOtherRunDisplay,
			jobId: this.jobId,
		};
	}

	get routeName() {
		return API_TO_STORAGE_RUNS_ITEM;
	}
}
