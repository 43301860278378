var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "listing-component",
    _vm._b(
      {
        scopedSlots: _vm._u([
          {
            key: "firestore_conf_doc_id",
            fn: function ({ item: { id, firestore_conf_doc_id } }) {
              return [
                _c(
                  "router-link",
                  { attrs: { to: { name: _vm.routeName, params: { id } } } },
                  [
                    _c("span", { staticClass: "font-weight-medium" }, [
                      _vm._v(_vm._s(firestore_conf_doc_id)),
                    ]),
                  ]
                ),
              ]
            },
          },
          {
            key: "dag_execution_date",
            fn: function ({ item: { dag_execution_date } }) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("moment")(dag_execution_date, "YYYY/MM/DD - HH:mm")
                    ) +
                    " "
                ),
              ]
            },
          },
          {
            key: "duration",
            fn: function ({ item: { duration } }) {
              return [
                _vm._v(" " + _vm._s(_vm.getFormattedDuration(duration)) + " "),
              ]
            },
          },
        ]),
      },
      "listing-component",
      _vm.listingComponentProps,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }